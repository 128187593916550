/**
 * Automatically opens the popup when the trigger is clicked.
 */
document.querySelectorAll("[aria-haspopup][aria-controls]").forEach((el) => {
  const popup = document.getElementById(el.getAttribute("aria-controls"));
  if (!popup) return;

  const srElement = el.querySelector(".sr-only");
  const srText = srElement.textContent;

  el.addEventListener("click", (e) => {
    let expanded = (el.getAttribute("aria-expanded") === "true") ? "false" : "true";
    e.preventDefault();
    e.stopPropagation();
    srElement.textContent = srElement.textContent === srText ? srText.replace("open", "close") : srText;
    el.setAttribute("aria-expanded", expanded);
    if (srElement.textContent.indexOf("close") >= 0) {
      popup.querySelector("input").focus();
    } else {
      el.focus();}
  });
});
