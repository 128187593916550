window.addEventListener("DOMContentLoaded", () => {
  // Handles lightbox links.

  document.querySelectorAll(`a[href][rel~="lightbox"]`).forEach((trigger) => {
    const href = trigger.getAttribute("href");
    const imgRegEx = /(?:\.jpg|\.png$|\.gif|\.svg|\.webp)(?:$|\?)/;

    if (href.startsWith("#")) {
      const modal = document.querySelector(`dialog[id="${href.substring(1)}"]`);
      if (modal) {
        trigger.addEventListener("click", (event) => {
          event.preventDefault();
          !modal.open && modal.show();
        });
      }
    } else if (imgRegEx.test(href)) {
      const modal = document.createElement("dialog");
      const alt = trigger.querySelector("img")?.getAttribute("alt");

      trigger.insertAdjacentElement("afterend", modal);
      modal.setAttribute("class", "modal lightbox rounded-lg");

      modal.innerHTML = `
        <button type="button" class="close translucent" aria-label="Close">close</button>
        <img src="${href}" alt="${alt}" />
      `;
      modal.insertAdjacentHTML("afterend", `<div class="backdrop"></div>`);

      trigger.addEventListener("click", (event) => {
        event.preventDefault();
        !modal.open && modal.show();
      });
    }

  });

  document.querySelectorAll("dialog").forEach(dialog => {
    // Handles "faux" close button using empty anchor to clear the `target:`
    const closeButton = dialog.querySelector(`a[href="#close"],button.close`);
    if (closeButton) {
      closeButton.addEventListener("click", function(event) {
        dialog.close();
      });
    }
  });

  document.querySelectorAll("dialog+.backdrop").forEach(backdrop => {
    const dialog = backdrop.previousElementSibling;
    backdrop.addEventListener("click", () => {
      dialog.close();
    });
  });
});

/**
 * Opens faux modal using CSS `target:` to actual modal.
 */
function openModalByHash() {
  const hash = window.location.hash;
  if (hash) {
    const modal = document.querySelector(`dialog[id="${hash.substring(1)}"]`);
    if (modal) {
      !modal.open && modal.show();
    }
  }
}

window.addEventListener("hashchange", openModalByHash, false);
openModalByHash();
