import lottie from '../../node_modules/lottie-web/build/player/lottie_light.js';
import AOS from "../common/aos.js";

document.querySelectorAll("[data-animation]").forEach(element => {
  const config =  element.getAttribute("data-animation");
  const anim = lottie.loadAnimation({
    container: element,
    renderer: 'svg',
    loop: false,
    autoplay: true,
    path: config
  });
});

document.querySelectorAll(".content blockquote").forEach(blockquote => {
  const div = document.createElement("div");
  const img = document.createElement("img");

  div.style = "position: absolute; bottom: 0; left: 100%; transform: translateX(-120px);"

  img.style = "max-width: none;";
  img.setAttribute("src", "/images/dataprojections.com/globe-line.svg");
  img.setAttribute("role", "presentation");
  img.setAttribute("aria-hidden", "true");
  img.setAttribute("data-aos", "fade-left");
  img.setAttribute("data-aos-delay", "500");
  img.setAttribute("data-aos-duration", "750");

  div.appendChild(img);

  blockquote.closest(".content").style = "overflow: hidden";
  blockquote.appendChild(div);
});


document.querySelectorAll(".animated [bucket='cards']").forEach(bucket => {
  let delay = 250;
  bucket.querySelectorAll("[member='card']").forEach(card => {
    card.setAttribute("data-aos", "fade-up");
    card.setAttribute("data-aos-delay", delay);
    card.setAttribute("data-aos-duration", "750");
    delay += 500;
  });
});

AOS.init();
