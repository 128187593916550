document.querySelectorAll(".slider-container").forEach(slider => {
  const slides = slider.querySelectorAll(".slide");
  const scrollContainer = slider.querySelector(".scroll-container");

  const prev = slider.querySelector("a.prev");
  const next = slider.querySelector("a.next");
  const nav = slider.querySelector("nav.slide-nav");

  if (slides.length <= 1) {
    prev?.remove();
    next?.remove();
    nav?.remove();
    return;
  }

  let activeSlide = 0;

  prev.style.visibility = "hidden";
  nav.classList.add("before:hidden");

  for (let i = 0; i < slides.length; i++) {
    nav.innerHTML +=`
    <a class="${i === 0 ? "active" : ""}">
      <span class="sr-only">${i}</span>
    </a>`;
  }

  nav.addEventListener("click", event => {
    if (event.target.tagName !== "A") return;

    let idx = Array.from(nav.children).indexOf(event.target);
    activeSlide = idx;
    adjustSlides();
  });

  prev.addEventListener("click", event => {
    event.preventDefault();
    if (activeSlide <= 0) return;
    activeSlide--;
    adjustSlides();
  });

  next.addEventListener("click", event => {
    event.preventDefault();
    if (activeSlide >= (slides.length - 1)) return;
    activeSlide++;
    adjustSlides();
  });

  window.addEventListener('load', function () {
    // Remove lazy loading so images will preload
    slider.querySelectorAll("img").forEach(img => {
      img.removeAttribute("loading");
    });
  });

  function adjustSlides() {
    let offset = slides[activeSlide].offsetLeft;
    scrollContainer.scrollTo({
      left: offset,
      behavior: "smooth"
    });

    prev.style.visibility = "visible";
    next.style.visibility = "visible";

    nav.classList.remove("before:hidden");
    nav.classList.remove("after:hidden");

    if (activeSlide === 0) {
      prev.style.visibility = "hidden";
      nav.classList.add("before:hidden");
    }
    if (activeSlide >= (slides.length - 1)) {
      next.style.visibility = "hidden";
      nav.classList.add("after:hidden");
    }

    nav.querySelector(".active").classList.remove("active");
    Array.from(nav.children).at(activeSlide).classList.add("active");
  }
});
