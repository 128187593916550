document.querySelectorAll(`[role="tablist"]`).forEach(tablist => {
  tablist.querySelectorAll(`[role="tab"][aria-controls]`).forEach(tab => {

    // remove this if I can get sam to output aria hidden properly
    if (tab.getAttribute("aria-selected") === "true") {
      const initialPanel = document.getElementById(tab.getAttribute("aria-controls"));
      initialPanel.setAttribute("aria-hidden", "false");
    }

    tab.addEventListener("click", () => {
      const activePanel = document.getElementById(tab.getAttribute("aria-controls"));
      tablist.querySelectorAll(`[role="tab"][aria-controls]`).forEach(tab => {
        tab.setAttribute("aria-selected", "false");
      });
      tab.setAttribute("aria-selected", "true");
      activePanel.parentNode.querySelectorAll(`[role="tabpanel"]`).forEach(panel => {
        panel.setAttribute("aria-hidden", "true");
      });
      activePanel.setAttribute("aria-hidden", "false");
    });
  });
});
