const $ = (selector, parent = document) => parent.querySelector(selector);
const $$ = (selector, parent = document) => parent.querySelectorAll(selector);

/**
 * Filters an item list based on the current URL search parameters.
 *
 * This assumes that the items have a `data-` attribute for each search
 * parameter, and they are siblings of each other.
 *
 * The search parameters can be set either by a GET form submission, or
 * by converting FormData to URLSearchParams.
 *
 * By default, elements containing filter attributes are applied, but
 * custom selector can also be specified in `selector` param.
 *
 * If a `limit` param is specified, the filtered results will be paginated,
 * starting from the page specified in `page` param, or 1.
 *
 * @param {URLSearchParams} searchParams
 * @returns {HTMLElement[]} - The remaining items after filtering.
 */
function filter(searchParams = new URL(location.href).searchParams) {
  let filterSelector = "",
    results = [];

  // Optionally, item selector can be specified in params.
  let itemSelector = searchParams.get("selector") || "";
  searchParams.delete("selector");

  // How many items to paginate
  const limit = Number(searchParams.get("limit"));
  // Starting paginated page index
  const page = Number(searchParams.get("page")) || 1;

  for (const [name, value] of searchParams) {
    if (["page", "limit"].includes(name)) continue;

    if (itemSelector) itemSelector += ",";
    itemSelector += `[data-${name}]`;
    if (value) {
      filterSelector += `[data-${name}*="${value}" i]`;
    } else {
      filterSelector += `[data-${name}]`;
    }

    // Sets selected value for matching inputs, including `<select>`s.
    $$(`[name=${name}]`).forEach(($input) => {
      $input.value = value;
    });
  }

  if (itemSelector) {
    // Toogles visibility of items based on the filter.
    const $items = $$(itemSelector);
    $items.forEach(($item) => {
      const hidden = filterSelector && !$item.matches(filterSelector);
      $item.style.display = hidden ? "none" : "";

      if (!hidden) {
        results.push($item);
      }
    });
  }

  if (limit) {
    // Pagination
    const count = results.length;

    // Further hides items not in pagination range.
    results.forEach(($item, index) => {
      const hidden = index < (page - 1) * limit || index >= page * limit;
      $item.style.display = hidden ? "none" : "";
    });
  }

  return results;
}

/**
 * Paginates a list of items.
 * @param {HTMLElement} $paginator Element that contains pagination links.
 * @param {URLSearchParams} searchParams Params to filter before paginating.
 */
function paginate(
  $paginator,
  searchParams = new URL(location.href).searchParams
) {
  const results = filter(searchParams);
  const page = Number(searchParams.get("page")) || 1;
  const limit = Number(searchParams.get("limit"));
  const url = new URL(location.href);

  // Handles pagination nav
  let $pages = $$("li", $paginator);
  let totalPages = $pages.length;

  totalPages--; // Skip the "next" page.
  // Loops backward and remove all pages except "prev" and "current".
  while (totalPages--) {
    const $page = $pages[totalPages];
    if (totalPages > 1) {
      $pages[totalPages].remove();
    }

    if (totalPages === 1) {
      url.searchParams.set("page", 1);
      const $link = $("a", $page);
      $link.removeAttribute("aria-current");
      $link.search = url.search;
    }
  }

  totalPages = Math.ceil(results.length / limit);

  let $page = $pages[1];
  for (let i = 2; i <= totalPages; i++) {
    const $newPage = $page.cloneNode(true);
    $page.after($newPage);
    $page = $newPage;

    const $link = $("a", $page);
    $link.innerHTML = `<span class="sr-only">page</span> ${i}`;
    url.searchParams.set("page", i);
    $link.search = url.search;
  }

  // Marks special pages.
  const $links = $$("li > a", $paginator);
  url.searchParams.set("page", page - 1);
  $links[0].search = url.search;

  $links[page].setAttribute("aria-current", "page");

  url.searchParams.set("page", page + 1);
  $links[$links.length - 1].search = url.search;

  $paginator.classList.toggle("hidden", !results.length);
}

// Handles form submission with "dialog" method to filter if applied.
// Note that "submit" event is not fired when calling `form.submit()`.
// Use `form.requestSubmit()` instead.
$$(`form.filters[method="dialog"]`).forEach(($form) => {
  const $paginator = $(`nav[aria-label="pagination"] ul`);
  const $empty = $paginator.parentElement.previousElementSibling;

  $form.addEventListener("change", function () {
    // Resets to page 1 if any of the filters changes.
    $form.elements["page"].value = "1";
    $form.requestSubmit();
  });

  $form.addEventListener("submit", (event) => {
    event.preventDefault();
    // Converts form data to URLSearchParams.
    const searchParams = new URLSearchParams(new FormData($form));

    // Updates browser's URL with new search params.
    const url = new URL(location.href);
    for (const [name, value] of searchParams) {
      url.searchParams.set(name, value);
    }

    if (history.pushState) {
      window.history.pushState(null, "", url.href);
    }

    paginate($paginator, searchParams);

    $empty.classList.toggle("hidden", !$paginator.classList.contains("hidden"));
  });

  // Handles browser's back button.
  window.addEventListener("popstate", (event) => {
    filter();
  });

  // When a pagination link is clicked, change the `page` input
  // and request submit.
  $paginator.addEventListener("click", (event) => {
    const $link = event.target.closest("a");
    if (!$link) return;
    event.preventDefault();

    const searchParams = new URL($link).searchParams;
    $form.elements["page"].value = searchParams.get("page");
    $form.requestSubmit();
  });

  // Populates form data with search params on page load.
  const searchParams = new URL(window.location.href).searchParams;
  searchParams.forEach((value, key) => {
    const input = $form.elements[key];
    if (!input) return;
    switch (input.type) {
      case "checkbox":
        input.checked = !!value;
        break;
      default:
        input.value = value;
        break;
    }
  });

  if (searchParams.get("author")?.length > 0) {
    $(".filter-message").innerHTML = `Showing articles written by: <strong><em>${searchParams.get("author")}</em></strong> - <small><a href=".">Clear</a></small>`;
  } else {
    $(".filter-message").remove();
  }

  $form.requestSubmit();
});
